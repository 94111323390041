@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply rounded-lg border-main shadow-sm focus:border-main2 focus:ring-main2;
  }

  [type="checkbox"],
  [type="radio"] {
    @apply rounded focus:ring-main;
  }
}

#pageDropDown {
  background-color: grey;
}

.react-bootstrap-table-pagination {
  z-index: -99;
}

.page-link {
  z-index: 0 !important;
  background-color: #0f65a8 !important;
}
